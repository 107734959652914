require("trix")
require("@rails/actiontext")
import Rails from '@rails/ujs';

Rails.start();

import * as bootstrap from "bootstrap"

window.bootstrap = bootstrap

import qs from 'qs'

window.qs = qs

import "../stylesheets/template_overrides.scss"
import "bootstrap-icons/font/bootstrap-icons.css"

import axios from "axios";

window.axios = axios;

// import "../csrf_grabber"
// import "../vue-controlled"

const onPageLoaded = () => {
    const elements = [].slice.call(document.querySelectorAll('.second-level-drawer-opener'))
    const secondLevelDrawers = [].slice.call(document.querySelectorAll('.aside-sub-menu[data-kt-drawer="true"]'))

    const flatpickrElements = [].slice.call(document.querySelectorAll('.do-flatpickr'));
    flatpickrElements.map(function (el) {
        el.flatpickr({
            enableTime: false,
            dateFormat: "d.m.Y",
        });
    })

    const flatpickrRangeElements = [].slice.call(document.querySelectorAll('.do-flatpickr-range'));
    flatpickrRangeElements.map(function (el) {
        el.flatpickr({
            mode: "range",
            wrap: true,
            enableTime: false,
            dateFormat: "d.m.Y",
        });
    })


    elements.map(function (element) {
        element.addEventListener('click', function () {
            KTDrawer.hideAll();
        })
    });
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', onPageLoaded);
} else {
    onPageLoaded();
}

window.submitFormFromModal = (el) => {
    const form = $(el).closest('.modal').find('form')[0];
    if (!form) {
        console.log("Form not found")
    }
    Rails.fire(form, 'submit')
}
window.prepareDictionaryModalForNewRecord = (path, callback) => {
    const modal = $("#dictionary-form-modal")
    const form = modal.find('form')
    form.attr('action', path)
    form.attr('method', 'post')
    form.find('.errors-block-container').html('')
    let methodInput = form.find("input[name=_method]")
    if (!methodInput.length) {
        methodInput = $("<input>")
        methodInput.attr('name', '_method')
        methodInput.attr('type', 'hidden')
        form.append(methodInput)
    }
    methodInput.val('post')
    form.find("[data-field]").each(function () {
        const el = $(this)
        if (el.attr('type') === 'checkbox') {

        } else {
            el.val('')
            el.trigger('change.select2')
        }
    })
    if (typeof callback === 'function') {
        callback()
    }
}

window.editDictionary = (action, record, callback) => {
    const modal = $("#dictionary-form-modal")
    const form = modal.find('form')
    form.attr('action', action)
        .attr('method', 'post')
        .find('.errors-block-container').html('')

    let methodInput = form.find("input[name=_method]")
    if (!methodInput.length) {
        methodInput = $("<input>")
        methodInput.attr('name', '_method')
        methodInput.attr('type', 'hidden')
        form.append(methodInput)
    }
    methodInput.val('patch')

    form.find("[data-field]").each(function () {
        const el = $(this)
        const name = el.data('field')
        if (Object.keys(record).indexOf(name) !== -1) {
            if (el.attr('type') === 'checkbox') {
                if (record[name]) {
                    // el.val(true);
                    el.prop('checked', true);
                } else {
                    // el.val(false);
                    el.prop('checked', false);
                }
            } else {
                if (record[name]) {
                    el.val(String(record[name]))
                }
            }
            if (el.hasClass('do-flatpickr')) {
                el.flatpickr({
                    enableTime: false,
                    dateFormat: "d.m.Y",
                });
            }
            el.trigger('change.select2')
        }
    })

    if (typeof callback === 'function') {
        callback(action, record)
    }

    modal.modal('show')
    return false;
}
window.setDictionaryFormAjaxListeners = (formClass, successHandler) => {
    $(function () {
        $(document).on('ajax:beforeSend', '.' + formClass, function (e) {
            const modal = $("#dictionary-form-modal")
            modal.find(".submit-btn").addClass('disabled')
        })
        $(document).on('ajax:complete', '.' + formClass, function (e) {
            const modal = $("#dictionary-form-modal")
            modal.find(".submit-btn").removeClass('disabled')
        })
        $(document).on('ajax:error', '.' + formClass, function (e) {
            const originalEvent = e?.originalEvent;
            const details = originalEvent?.detail;
            const xhr = details && details[2];

            if (!xhr) return;

            if (xhr.status === 422) {
                const returnedBody = $(xhr.responseText)
                const errorsBlock = returnedBody.find('.errors-block-container')
                if (errorsBlock) {
                    $("." + formClass + " .errors-block-container").html(errorsBlock.html())
                }
            } else {
                alert("При выполнении запроса произошла ошибка");
            }
        })

        $(document).on('ajax:success', '.' + formClass, function (e) {
            if (successHandler && typeof successHandler === 'function') {
                successHandler(e)
                return;
            }
            const originalEvent = e?.originalEvent;
            const details = originalEvent?.detail;
            if (!details) return;

            const returnedDOM = details[0];
            const documentsTable = $(returnedDOM).find(".dictionary-table")
            if (documentsTable.length) {
                $(".dictionary-table").replaceWith(documentsTable[0].outerHTML)
            }

            $("#dictionary-form-modal").modal('hide')
        })

    })
}

window.setDarkMode = (value) => {
    const params = qs.parse(document.location.search.substring(1))
    params['dm'] = value ? 1 : 0;
    let paramsString = qs.stringify(params)
    if (paramsString) paramsString = '?' + paramsString;
    document.location = document.location.pathname + paramsString
}

window.addSelect2Vue = (root) => {
    root = root ?? document.body
    if (!root.querySelectorAll) return;
    const elements = [].slice.call(root.querySelectorAll('[data-control="select2-vue"]'));

    elements.map(function (element) {
        // if ($(element).data('select2')) return;

        const options = {
            dir: document.body.getAttribute('direction')
        };

        if (element.dataset['select2Parent']) {
            if (element.dataset['select2Parent'] === 'closest-div') {
                options.dropdownParent = $(element).closest('div')
            } else {
                options.dropdownParent = element.dataset['select2Parent'];
            }
        }

        if (element.dataset['overrideTemplateSelection'] && typeof window[element.dataset['overrideTemplateSelection']] === 'function') {
            options.templateSelection = window[element.dataset['overrideTemplateSelection']];
        }

        if (element.dataset['overrideTemplateResult'] && typeof window[element.dataset['overrideTemplateResult']] === 'function') {
            options.templateResult = window[element.dataset['overrideTemplateResult']];
        }

        // noinspection EqualityComparisonWithCoercionJS
        if (element.getAttribute('data-hide-search') == 'true') {
            options.minimumResultsForSearch = Infinity;
        }

        if (element.dataset['ajax'] && typeof window[element.dataset['ajax']] === 'object') {
            options.ajax = window[element.dataset['ajax']]
        }

        $(element).select2(options);

        $(element).on('select2:select', function (e) {
            element.dispatchEvent(new Event('change'));
        })
        $(element).on('select2:unselect', function (e) {
            element.dispatchEvent(new Event('change'));
        })
    });
}

window.addSelect2Defer = (root) => {
    // const flatpickrElements = [].slice.call($(root).find('.do-flatpickr'));
    // flatpickrElements.map(function (element) {
    //     $(element).flatpickr({
    //         enableTime: false,
    //         dateFormat: "d.m.Y",
    //     });
    // });

    root = root ?? document.body

    const elements = [].slice.call($(root).find('[data-control="select2-defer"]'));

    elements.map(function (element) {
        if ($(element).hasClass('select2-hidden-accessible')) return;

        const options = {
            dir: document.body.getAttribute('direction')
        };

        if (element.getAttribute('data-hide-search') === 'true') {
            options.minimumResultsForSearch = Infinity;
        }

        $(element).select2(options);
    });

    return false;
}